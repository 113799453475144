import {
  Avatar,
  Button,
  Code,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
  useDisclosure,
} from "@nextui-org/react";
import { AddInstancePayload, AddInstanceResponse } from "../../interfaces";
import { useAuthContext, useInstanceContext } from "../../context";
import { useState } from "react";
import FacebookIcon from "../../assets/icons/facebookIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import GearIcon from "../../assets/icons/gearIcon";
import MessageIcon from "../../assets/icons/messageIcon";
import { useNavigate } from "react-router-dom";
import ChartIcon from "../../assets/icons/chartIcon";

export const InstancesList = () => {
  const { user } = useAuthContext();
  const { instances, updateInstance, getUserInstances } = useInstanceContext();
  const navigate = useNavigate();
  const [selectedInstance, setSelectedInstance] = useState<
    AddInstanceResponse | undefined
  >(undefined);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const getCYTJSon = () => {
    return {
      Id: selectedInstance?.cyt?.id,
      Meta: {
        PageId: selectedInstance?.meta?.providerId,
        AccessToken: selectedInstance?.meta?.token,
        VerifyToken: selectedInstance?.meta?.type,
        ApiUrl: "https://graph.facebook.com/v20.0/",
        Type: selectedInstance?.meta?.type,
      },
      ChatCyt: {
        Url: `${selectedInstance?.cyt?.IMC?.url ?? "[Dominio]"}:${selectedInstance?.cyt?.IMC?.port ?? "[Puerto]"
          }/FacebookMessenger/ManageMessenger`,
        Token: selectedInstance?.cyt?.IMC?.token,
      },
    };
  };
  const updateSubscriptions = (value: "all" | "feed" | "messenger") => {
    let newInstance = { ...selectedInstance! };
    if (newInstance.meta) {
      newInstance.meta.subscriptions = value;
    }
    setSelectedInstance(newInstance);
  };
  return instances.length > 0 ? (
    <div className="flex flex-col items-center justify-center mt-4 w-full">
      <div className="text-lg text-white">Instancias encontradas:</div>
      <div className="flex flex-col items-center justify-center mt-4 gap-2 w-full">
        {instances.map((instance: AddInstanceResponse) => (
          <div
            key={instance._id}
            className="shadow-lg rounded-lg p-4 bg-white text-center flex items-center justify-between w-full"
          >
            <section className="flex items-center justify-center">
              <Avatar
                size="lg"
                src={
                  instance.meta?.profilePicture ??
                  "https://i.pravatar.cc/150?u=a042581f4e29026024d"
                }
              />
              {instance.meta?.type === "facebook" ? (
                <FacebookIcon width="50" height="50" color="#4267B2" />
              ) : (
                <InstagramIcon width="50" height="50" color="#F56040" />
              )}
              <section className="flex flex-col items-start justify-center ml-2">
                <span className="text-primary text-lg">{instance.name}</span>
                <span className="text-secondary text-xs font-light">
                  {instance.meta!.providerId}
                </span>
              </section>
            </section>
            {user?.role === "cyt" && (
              <div className="flex items-center justify-center space-x-2 text-primary">
                <Button
                  onClick={() => {
                    navigate(`/posts/${instance.meta?.providerId}`);
                  }}
                  className="ml-2 px-1 py-1 text-primary bg-transparent hover:bg-gray-300">
                  <ChartIcon size="20" />
                </Button>
                <Button
                  onClick={() => {
                    navigate(`/chat`);
                  }}
                  className="ml-2 px-1 py-1 text-white bg-transparent hover:bg-gray-300"
                >
                  <MessageIcon width="20" height="20" color="#4267B2" />
                </Button>
                <Button
                  onClick={() => {
                    setSelectedInstance(instance);
                    onOpen();
                  }}
                  className="ml-2 px-1 py-1 text-white bg-transparent hover:bg-gray-300"
                >
                  <GearIcon width="20" height="20" color="#4267B2" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="4xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <section className="flex flex-col items-start justify-center">
                  <span className="text-primary text-lg">
                    {selectedInstance?.name}
                  </span>
                  <span className="text-secondary text-xs font-light">
                    {selectedInstance?.meta!.providerId}
                  </span>
                </section>
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-row gap-4">
                  <Input
                    label="URL de SMI"
                    value={selectedInstance?.cyt?.url}
                    onChange={(e) => {
                      let newInstance = { ...selectedInstance! };
                      newInstance.cyt = {
                        url: e.target.value,
                        id: selectedInstance?.cyt?.id ?? 0,
                      };
                      setSelectedInstance(newInstance);
                    }}
                  />
                  <Input
                    label="Numero de instancia"
                    value={selectedInstance?.cyt?.id?.toString() ?? "0"}
                    type="number"
                    onChange={(e) => {
                      let newInstance = { ...selectedInstance! };
                      newInstance.cyt = {
                        url: selectedInstance?.cyt?.url ?? "",
                        id: Number(e.target.value),
                      };
                      setSelectedInstance(newInstance);
                    }}
                  />
                </div>
                <div className="flex flex-row gap-4 mt-4">
                  <Input
                    label="URL de IMC"
                    value={selectedInstance?.cyt?.IMC?.url}
                    onChange={(e) => {
                      let newInstance = { ...selectedInstance! };
                      newInstance.cyt = {
                        ...newInstance.cyt,
                        IMC: {
                          ...newInstance.cyt?.IMC,
                          url: e.target.value,
                        },
                      };
                      setSelectedInstance(newInstance);
                    }}
                  />
                  <Input
                    label="Puerto de IMC"
                    value={selectedInstance?.cyt?.IMC?.port?.toString() ?? "0"}
                    type="number"
                    onChange={(e) => {
                      let newInstance = { ...selectedInstance! };
                      newInstance.cyt = {
                        ...newInstance.cyt,
                        IMC: {
                          ...newInstance.cyt?.IMC,
                          port: Number(e.target.value),
                        },
                      };
                      setSelectedInstance(newInstance);
                    }}
                  />
                  <Input
                    label="Token de IMC"
                    value={selectedInstance?.cyt?.IMC?.token}
                    onChange={(e) => {
                      let newInstance = { ...selectedInstance! };
                      newInstance.cyt = {
                        ...newInstance.cyt,
                        IMC: {
                          ...newInstance.cyt?.IMC,
                          token: e.target.value,
                        },
                      };
                      setSelectedInstance(newInstance);
                    }}
                  />
                </div>
                {user?.role === "cyt" && (
                  <section>
                    <Code
                      style={{
                        overflowX: "auto",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                      }}
                    >
                      <pre>{JSON.stringify(getCYTJSon(), null, 2)}</pre>
                    </Code>
                    <p className="text-secondary my-2">Suscripciones</p>
                    <div className="flex gap-4">
                      <Switch
                        isSelected={
                          selectedInstance?.meta?.subscriptions === "all"
                        }
                        onValueChange={(value) =>
                          value && updateSubscriptions("all")
                        }
                        size="sm"
                      >
                        Todos
                      </Switch>
                      <Switch
                        isSelected={
                          selectedInstance?.meta?.subscriptions === "feed"
                        }
                        onValueChange={(value) =>
                          value && updateSubscriptions("feed")
                        }
                        size="sm"
                      >
                        Feeds
                      </Switch>
                      <Switch
                        isSelected={
                          selectedInstance?.meta?.subscriptions === "messenger"
                        }
                        onValueChange={(value) =>
                          value && updateSubscriptions("messenger")
                        }
                        size="sm"
                      >
                        Messenger
                      </Switch>
                    </div>
                  </section>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cerrar
                </Button>
                <Button
                  className="bg-purple-900 hover:bg-purple-600 text-white"
                  onClick={async () => {
                    let response = await updateInstance(
                      {
                        cyt: selectedInstance?.cyt,
                        meta: selectedInstance?.meta,
                        name: selectedInstance?.name ?? "-",
                        owner: selectedInstance?.owner ?? "-",
                      },
                      selectedInstance!._id
                    );
                    if (response) {
                      getUserInstances(selectedInstance!.owner);
                      onClose();
                    }
                  }}
                >
                  Guardar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  ) : (
    <></>
  );
};
