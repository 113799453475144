import { useParams } from "react-router-dom";
import { useAuthContext, useInstanceContext } from "../context";
import { useEffect, useState } from "react";
import { AddInstanceResponse, Post } from "../interfaces";
import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Image, Tooltip } from "@nextui-org/react";
import FacebookIcon from "../assets/icons/facebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import { usePostStore } from "../stores";
import formatDate from "../utils/formatedDate";
import FireIcon from "../assets/icons/fireIcon";
import ChechCircleIcon from "../assets/icons/checkCircleIcon";
import LikeIcon from "../assets/icons/likeIcon";
import ShareIcon from "../assets/icons/shareIcon";
import { useNavigate } from "react-router-dom";
const PostComponent = ({ post }: { post: Post }) => {

    return <Card className="w-full my-5 p-0">
        <CardHeader className="flex p-0 flex-col items-start">
            <Image
                src={post.fullPicture}
                alt="postImage"
                className="w-full p-0 m-0 rounded-t-lg rounded-b-none"
            />
        </CardHeader>
        <CardBody>
            <section className="py-3 m-0">
                <p className="text-lg subpixel-antialiased mb-2">{post.message}</p>
                <section className="flex justify-between">
                    <p className="font-light text-xs text-cyan-700">
                        Creado en: {formatDate(new Date(post.createdTime).getTime())}
                    </p>
                    <p
                        className="font-light text-xs text-cyan-700"
                    >
                        Post ID: {post.id}
                    </p>
                </section>
            </section>
        </CardBody>
        <CardFooter className="flex justify-between">
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.isPopular ? 'text-red-900' : 'text-gray-600'}`}>
                <FireIcon /> {post.isPopular ? 'Es Popular' : 'Aun no es popular'}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.isPublished ? 'text-primary' : 'text-gray-600'}`}>
                <ChechCircleIcon /> {post.isPublished ? 'Publicado' : 'No Publicado'}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.likes > 0 ? 'text-primary' : 'text-gray-600'}`}>
                <LikeIcon /> {post.likes}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.shares > 0 ? 'text-primary' : 'text-gray-600'}`}>
                <ShareIcon /> {post.shares}
            </section>
        </CardFooter>
    </Card>
}
const PostPage = () => {
    const { instanceId } = useParams();
    const { instances } = useInstanceContext();
    const [selectedInstance, setSelectedInstance] = useState<AddInstanceResponse | null>(null);
    const { getAllPosts } = usePostStore();
    const [posts, setPosts] = useState<Post[]>();
    const { user } = useAuthContext();
    const getAsync = async (id: string) => {
        const response = await getAllPosts(id);
        console.log(response)
        setPosts(response);
    }
    const navigate = useNavigate();
    useEffect(() => {
        if (!user) {
            navigate("/");
        }
    }, [navigate, user]);
    useEffect(() => {

        getAsync(instanceId!);

        if (instanceId) {
            setSelectedInstance(instances.find((instance) => instance.meta?.providerId === instanceId) ?? null);
        }
    }, [instanceId])
    return <section className="w-full flex flex-col items-center justify-center pt-10">

        <section className="p-5 shadow rounded-lg sm:w-3/4 md:w-3/5 lg:w-2/3 xl:w-2/5">
            <Button className="w-10 mb-5" color="primary" variant="light" size="lg" onClick={() => navigate("/welcome")}>
                {"< Volver"}
            </Button>
            <section className="flex gap-4">
                <Avatar
                    className="w-15 h-15"
                    src={selectedInstance?.meta?.profilePicture}
                />
                {selectedInstance?.meta?.type === "facebook" ? (
                    <FacebookIcon width="50" height="50" color="#4267B2" />
                ) : (
                    <InstagramIcon width="50" height="50" color="#F56040" />
                )}
                <section className="flex flex-col">
                    <a className="text-primary font-bold text-lg">{selectedInstance?.name}</a>
                    <a className="text-secondary font-light text-sm">{selectedInstance?.meta?.providerId}</a>
                </section>
            </section>
            <p className="text-lg font-black mt-5">Estadisticas</p>
            <p className="text-sm font-light">Estadisticas de los ultimos Posts realizados</p>
            {
                posts ? posts.map((post) => <PostComponent post={post} />) : <p>Sin Posts registrados</p>
            }
        </section>
    </section>
}
export default PostPage;