import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import {
  direction,
  Instance,
  type Event as EventInterface,
  type Sender,
} from "../../interfaces";
import MessageComponent from "./messageComponent";
import PostComponent from "./postComponent";
import formatDate from "../../utils/formatedDate";
import EyeIcon from "../../assets/icons/eyeIcon";
interface EventComponentInterface {
  sender: Sender;
  event: EventInterface;
  instance: Instance;
}

const EventComponent = ({
  event,
  instance,
  sender,
}: EventComponentInterface) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const renderComment = () => {
    return event.direction === direction.Incoming ? (
      <section>
        <button
          onClick={onOpen}
          className="bg-cyan-200 text-cyan-900 m-3 flex flex-row justify-between items-center max-w-1/2 md:max-w-2/3 sm:max-w-full p-3 rounded-md"
        >
          <section className="flex flex-col items-start mr-10">
            <p className="font-extralight text-tiny">Comentario</p>
            <p className="font-semibold text-left">{event.message}</p>
            <p className="text-tiny mt-1">{formatDate(event.timestamp)}</p>
          </section>
          <EyeIcon />
        </button>

        <Modal size="lg" isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <PostComponent
                sender={sender}
                event={event}
                instance={instance}
                onClose={onClose}
              />
            )}
          </ModalContent>
        </Modal>
      </section>
    ) : null;
  };
  return event.type === "comment" ? (
    renderComment()
  ) : (
    <MessageComponent event={event} instance={instance} sender={sender} />
  );
};
export default EventComponent;
