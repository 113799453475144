import { useEffect, useState } from "react";
import {
  Sender,
  Instance,
  type Event as EventInterface,
  Post,
  direction,
} from "../../interfaces";
import { Card, CardBody, CardHeader, Divider, Image } from "@nextui-org/react";
import formatDate from "../../utils/formatedDate";
import CommentComponent from "./commentComponent";
import { usePostStore } from "../../stores";
import { useChatContext } from "../../context";
import FireIcon from "../../assets/icons/fireIcon";
import ChechCircleIcon from "../../assets/icons/checkCircleIcon";
import LikeIcon from "../../assets/icons/likeIcon";
import ShareIcon from "../../assets/icons/shareIcon";

interface PostComponentInterface {
  sender: Sender;
  event: EventInterface;
  instance: Instance;
  onClose: () => void;
}
const PostComponent = ({
  sender,
  event,
  instance,
  onClose,
}: PostComponentInterface) => {
  const { posts, getPost, addCommentToPost, deleteComment } = usePostStore();
  const { chats, deleteEvent } = useChatContext();
  const [replies, setReplies] = useState<EventInterface[] | null>(null);
  const [post, setPost] = useState<Post | null>(null);
  useEffect(() => {
    const fetchPost = async () => {
      if (event.comment) {
        let post = await getPost(
          instance.providerId,
          event.comment.relatedPostId
        );
        console.log('post', post);
        setPost(post);
      }
    };
    fetchPost();
  }, [event.comment, getPost, instance.providerId]);
  useEffect(() => {
    console.log("posts", posts);
    const relatedPost = posts?.find(
      (p) => p.id === event.comment?.relatedPostId
    );
    if (relatedPost) {
      console.log("post updated", relatedPost);
      setPost(relatedPost);
    }
  }, [event.comment, posts]);
  useEffect(() => {
    if (event.comment) {
      addCommentToPost({ event, instance, sender });
    }
  }, [
    addCommentToPost,
    event,
    event.comment,
    getPost,
    instance,
    instance.providerId,
    sender,
  ]);
  useEffect(() => {
    let chat = chats.find(
      (chat) => chat.consumer.providerId === sender.providerId
    );
    let filteredEvents = chat?.events.filter(
      (e) => e.comment?.parentId === event.providerId
    );
    setReplies(filteredEvents || null);
  }, [chats, event.providerId, sender.providerId]);
  return post ? (
    <Card className="w-full p-0">
      <CardHeader className="flex p-0 flex-col items-start">
        <Image
          src={post.fullPicture}
          alt="postImage"
          className="w-full p-0 m-0 rounded-t-lg rounded-b-none"
        />
      </CardHeader>
      <CardBody>
        <section className="py-3 m-0">
          <p className="text-lg subpixel-antialiased mb-2">{post.message}</p>
          <section className="flex justify-between">
            <p className="font-light text-xs text-cyan-700">
              {formatDate(new Date(post.createdTime).getTime())}
            </p>
            <button
              className="font-light text-xs text-cyan-700"
              onClick={() => window.open(event.comment?.relatedUrl, "_blank")}
            >
              Post ID: {post.id}
            </button>
          </section>
          <section className="flex mt-5 justify-between">
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.isPopular ? 'text-red-900' : 'text-gray-600'}`}>
              <FireIcon /> {post.isPopular ? 'Es Popular' : 'Aun no es popular'}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.isPublished ? 'text-primary' : 'text-gray-600'}`}>
              <ChechCircleIcon /> {post.isPublished ? 'Publicado' : 'No Publicado'}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.likes > 0 ? 'text-primary' : 'text-gray-600'}`}>
              <LikeIcon /> {post.likes}
            </section>
            <section className={`flex gap-1 justify-center align-center mx-3 ${post.shares > 0 ? 'text-primary' : 'text-gray-600'}`}>
              <ShareIcon /> {post.shares}
            </section>
          </section>
          <Divider className="mt-5" />
          <p className="mt-2 text-cyan-800 font-semibold">Comentario</p>
        </section>
        <CommentComponent
          isPrimary
          sender={sender}
          event={event}
          instance={instance}
          onDelete={() => {
            if (window.confirm("Estas seguro de eliminar este comentario?")) {
              deleteComment(event.providerId, instance.providerId, post.id);
              deleteEvent(event.providerId);
              onClose();
            }
          }}
        />
        {replies?.map((ev) => {
          return (
            <CommentComponent
              key={ev.providerId}
              sender={sender}
              event={ev}
              instance={instance}
              onDelete={() => {
                if (
                  window.confirm("Estas seguro de eliminar este comentario?")
                ) {
                  deleteEvent(event.providerId);
                  deleteComment(event.providerId, instance.providerId, post.id);
                }
              }}
            />
          );
        })}
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};
export default PostComponent;
